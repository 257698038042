<template>
  <div class="calendar-box">
    <Calendar
      :min-date="data.minDate"
      :max-date="data.maxDate"
      :poppable="false"
      :default-date="time"
      :show-title="false"
      :show-mark="false"
      :allow-same-day="true"
      :row-height="42"
      :style="{ height: '300px' }"
      color="#1773FA"
      type="range"
      @confirm="selectDate"
    ></Calendar>
    <!-- <Calendar
      title="日历"
      :poppable="false"
      :show-confirm="false"
      :style="{ height: '500px' }"
    /> -->
  </div>
</template>

<script setup>
import { Calendar } from 'vant'
import { reactive, getCurrentInstance, ref, defineProps, onMounted } from 'vue'
// import moment from 'moment'
const { proxy } = getCurrentInstance()
const props = defineProps({
  propTime: {
    type: Array,
    default() {
      return []
    }
  }
})

const data = reactive({
  minDate: new Date(new Date() - 30 * 24 * 60 * 60 * 1000),
  //   maxDate: new Date(new Date() + 60 * 24 * 60 * 60 * 1000)
  //   maxDate: new Date(new Date(2022, 5, 20))
  maxDate: new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  )
})
const time = ref([])
// const showCalendar = ref(false)

const selectDate = (val) => {
  let arr = JSON.parse(JSON.stringify(val))
  proxy.$emit('selectDate', arr)
}
onMounted(() => {
  time.value = props.propTime
  if (time.value.length === 0) {
    time.value = null
  }
})
</script>

<style lang="less" scoped>
.calendar-box {
  :deep(.van-calendar__bottom-info) {
    display: none;
  }
  //   :deep(.van-calendar__day--start) {
  //     // border-radius: 50%;
  //     // background: ;
  //     border-radius: 17px 0 0 17px;
  //   }
  //   :deep(.van-calendar__day--end) {
  //     border-radius: 0 17px 17px 0;
  //   }
  :deep(.van-button--round) {
    border-radius: 5px;
    line-height: 44px;
    height: 44px;
  }
}
</style>
